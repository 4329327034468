body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

#root {
  height: 100%;
}

h1,
h2 {
  font-family: 'Noto', serif;
  color: #ffffff;
  margin: 0.5rem;
}

h3,
p,
a {
  color: #ffffff;
  margin: 0.5rem;
  font-family: 'Inter', sans-serif;
}

h1,
h2 {
  font-weight: 200;
  line-height: 110%;
  margin: 0.5rem;
}

h1 {
  text-transform: uppercase;
}

a {
  cursor: pointer;
}

a:hover {
  text-decoration: underline;
}
